import React, {useEffect, useState} from 'react';
import './App.css';
import Sale from './components/Sale';
import VAT from './components/VAT';
import SaleMargin from './components/SaleMargin';

function App() {

    const [page, setPage] = useState(0);

    const setThisComponent = () => {
        if (page === 0) {
            return <Sale setPage={setPage} />;
        }
        else if (page === 1) {
            return <SaleMargin setPage={setPage} />;
        }
        else {
            return <VAT setPage={setPage} />;
        }
    };

    useEffect(() => {
        setThisComponent();
    }, [page]);

    return (
        <>
            <div className="App">
                <div className="Header">
                    <div className="Header-Title">판매 계산기</div>
                    <div className="Header-Sub-Title">판매가, 마진, 부가세 계산을 해보세요</div>
                </div>
                {setThisComponent()}
                <div className="Footer">Copyright 2022. MR.DEVELLO All rights reserved.</div>
            </div>
            <ins className="adsbygoogle"
                 style={{display: 'block'}}
                 data-ad-client="ca-pub-6163050877922629"
                 data-ad-slot="2465804595"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </>

    );
}

export default App;

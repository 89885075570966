import React, {useEffect, useState} from 'react';
import '../App.css';
import dollorImage from "../assets/images/dollor.png";

function VAT(Props: any) {

    const [ThisCalculator, setThisCalculator] = useState(0);                // 계산기 타입(0: 합계금액 계산, 1: 공급가액 계산)

    /* Calculator1 - 결과 */
    const [Cal1SupplyPrice, setCal1SupplyPrice] = useState(0);              // 공급가액
    const [Cal1TaxPrice, setCal1TaxPrice] = useState(0);                    // 세액

    /* Calculator2 - 결과 */
    const [Cal2SumPrice, setCal2SumPrice] = useState(0);                    // 합계금액
    const [Cal2TaxPrice, setCal2TaxPrice] = useState(0);                    // 세액


    /* Calculator1 - 입력 */
    const [Cal1SumPrice, setCal1SumPrice] = useState(0);                    // 합계금액

    /* Calculator2 - 입력 */
    const [Cal2SupplyPrice, setCal2SupplyPrice] = useState(0);              // 공급가액

    useEffect(() => {
        console.log(Cal2SumPrice);
        if (ThisCalculator === 0) {
            setCal2SupplyPrice(0);
            setCal2SumPrice(0);
            setCal2TaxPrice(0);
            if (Cal1SumPrice > 0) {
                setCal1TaxPrice(Cal1SumPrice / 11);
                setCal1SupplyPrice(Cal1SumPrice - Cal1TaxPrice);
                console.log('?');
            } else {
                setCal1TaxPrice(0);
                setCal1SupplyPrice(0);
            }
        } else {
            setCal1SumPrice(0);
            setCal1SupplyPrice(0);
            setCal1TaxPrice(0);
            if (Cal2SupplyPrice > 0) {
                setCal2TaxPrice(Cal2SupplyPrice * 0.1);
                setCal2SumPrice(Cal2TaxPrice + Cal2SupplyPrice);
            } else {
                setCal2SumPrice(0);
                setCal2TaxPrice(0);
            }
        }
    }, [Cal1SumPrice, Cal1SupplyPrice, Cal1TaxPrice, Cal2SupplyPrice, Cal2TaxPrice, ThisCalculator]);

    return (
        <div className="Contents-Container">
            <div className="Contents Contents-Small">
                <div className="Calculator-Contents">
                    <div className="Calculator-Tabs">
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(0)}>판매가계산기</div>
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(1)}>판매가 마진계산기</div>
                        <div className="Calculator-Tab Calculator-Tab-Deco">부가세계산기</div>
                    </div>
                    <div className="Calculator-Values">
                        <div className="Sub-Tab">
                            {ThisCalculator === 0 && (
                                <>
                                    <div className="Cal-Tab Cal-Tab-Choose">합계금액으로 계산</div>
                                    <div className="Cal-Tab" onClick={() => setThisCalculator(1)}>공급가로 계산</div>
                                </>
                            )}
                            {ThisCalculator === 1 && (
                                <>
                                    <div className="Cal-Tab" onClick={() => setThisCalculator(0)}>합계금액으로 계산</div>
                                    <div className="Cal-Tab Cal-Tab-Choose">공급가로 계산</div>
                                </>
                            )}
                        </div>
                        {ThisCalculator === 0 && (
                            <>
                                <div className="Calculator-Text">합계금액 (원)</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" max="10000" value={Cal1SumPrice} onChange={el => {
                                        let num: any = el.target.value;
                                        setCal1SumPrice(num*1);
                                    }}/>
                                    <input type="number" value={Cal1SumPrice} onChange={el => {
                                        let num: any = el.target.value;
                                        setCal1SumPrice(num*1);
                                    }}/>
                                    <span>원</span>
                                </div>
                            </>
                        )}
                        {ThisCalculator === 1 && (
                            <>
                                <div className="Calculator-Text">공급가 (원)</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" max="10000" value={Cal2SupplyPrice} onChange={el => {
                                        let num: any = el.target.value;
                                        setCal2SupplyPrice(num*1);
                                    }}/>
                                    <input type="number" value={Cal2SupplyPrice} onChange={el => {
                                        let num: any = el.target.value;
                                        setCal2SupplyPrice(num*1);
                                    }}/>
                                    <span>원</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="Result-Contents">
                    {ThisCalculator === 0 && (
                        <>
                            <div className="Result-Title">합계금액으로 계산</div>
                            <div className="Result-Value"><span>{Cal1SupplyPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 공급가</div>
                            <div className="Result-Value"><span>{Cal1TaxPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 세액</div>
                        </>
                    )}
                    {ThisCalculator === 1 && (
                        <>
                            <div className="Result-Title">공급가로 계산</div>
                            <div className="Result-Value"><span>{Cal2SumPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 합계금액</div>
                            <div className="Result-Value"><span>{Cal2TaxPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 세액</div>
                        </>
                    )}
                    <img className="Result-Image" src={dollorImage}/>
                </div>
            </div>
        </div>
    );
}

export default VAT;

import React, {useEffect, useState} from 'react';
import '../App.css';
import dollorImage from "../assets/images/dollor.png";

function Sale(Props: any) {

    /* 결과 */
    const [SalePrice, setSalePrice] = useState(0);                          // 판매가
    const [SellerMargin, setSellerMargin] = useState(0);                    // 판매자 마진
    const [DiscountPrice, setDiscountPrice] = useState(0);                  // 즉시 할인액
    const [Commission, setCommission] = useState(0);                        // 수수료

    /* 입력받는 값 */
    const [SupplyPrice, setSupplyPrice] = useState(0);                      // 공급가
    const [SellerMarginPer, setSellerMarginPer] = useState(0);              // 판매자 마진율
    const [DiscountPricePer, setDiscountPricePer] = useState(0);            // 즉시 할인율
    const [CommissionPer, setCommissionPer] = useState(0);                  // 마켓 수수료율



    useEffect(() => {

        if (SupplyPrice > 0 && SellerMarginPer > 0) {
            setSalePrice(SupplyPrice / (1 - SellerMarginPer / 100 - DiscountPricePer / 100 - CommissionPer / 100));
            setSellerMargin(SalePrice * SellerMarginPer / 100);
            setDiscountPrice(SalePrice * DiscountPricePer / 100);
            setCommission(SalePrice * CommissionPer / 100);
        } else {
            setSalePrice(0);
            setSellerMargin(0);
            setDiscountPrice(0);
            setCommission(0);
        }
        console.log(SupplyPrice + ' / ' + SellerMarginPer + ' / ' + DiscountPricePer + ' / ' + CommissionPer);
        console.log(SalePrice * SellerMarginPer / 100);
        console.log(SalePrice * DiscountPricePer / 100);
        console.log(SalePrice * CommissionPer / 100);
    }, [CommissionPer, DiscountPricePer, SalePrice, SellerMargin, SellerMarginPer, SupplyPrice]);

    return (
        <div className="Contents-Container">
            <div className="Contents Contents-Normal">
                <div className="Calculator-Contents">
                    <div className="Calculator-Tabs">
                        <div className="Calculator-Tab Calculator-Tab-Deco">판매가계산기</div>
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(1)}>판매가 마진계산기</div>
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(2)}>부가세계산기</div>
                    </div>

                    <div className="Calculator-Values">
                        <div className="Calculator-Text">공급가 (원)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="10000" value={SupplyPrice} onChange={el => {
                                let num : any = el.target.value;
                                setSupplyPrice(num);
                            }} />
                            <input type="number" value={SupplyPrice} onChange={el => {
                                let num : any = el.target.value;
                                setSupplyPrice(num);
                            }} />
                            <span>원</span>
                        </div>
                        <div className="Calculator-Text">판매자 마진율 (%)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="100" value={SellerMarginPer} onChange={el => {
                                let num : any = el.target.value;
                                setSellerMarginPer(num);
                            }} />
                            <input type="number" value={SellerMarginPer} onChange={el => {
                                let num : any = el.target.value;
                                setSellerMarginPer(num);
                            }} />
                            <span>%</span>
                        </div>
                        <div className="Calculator-Text">즉시 할인율 (%)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="100" value={DiscountPricePer} onChange={el => {
                                let num : any = el.target.value;
                                setDiscountPricePer(num);
                            }} />
                            <input type="number" value={DiscountPricePer} onChange={el => {
                                let num : any = el.target.value;
                                setDiscountPricePer(num);
                            }} />
                            <span>%</span>
                        </div>
                        <div className="Calculator-Text">마켓 수수료율 (%)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="100" value={CommissionPer} onChange={el => {
                                let num : any = el.target.value;
                                setCommissionPer(num);
                            }} />
                            <input type="number" value={CommissionPer} onChange={el => {
                                let num : any = el.target.value;
                                setCommissionPer(num);
                            }} />
                            <span>%</span>
                        </div>
                    </div>

                </div>
                <div className="Result-Contents">
                    <div className="Result-Title">판매가 계산기</div>
                    <div className="Result-Value"><span>{SalePrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 판매가</div>
                    <div className="Result-Value"><span>{SellerMargin.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 판매자 마진</div>
                    <div className="Result-Value"><span>{DiscountPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 즉시 할인액</div>
                    <div className="Result-Value"><span>{Commission.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 수수료</div>
                    <img className="Result-Image" src={dollorImage}/>
                </div>
            </div>
        </div>
    );
}

export default Sale;

import React, {useEffect, useState} from 'react';
import '../App.css';
import dollorImage from "../assets/images/dollor.png";

function SaleMargin(Props: any) {

    /* 결과 */
    const [PurchasePrice, setPurchasePrice] = useState(0);                  // 매입가
    const [SalePrice, setSalePrice] = useState(0);                          // 판매가
    const [ShippingPrice, setShippingPrice] = useState(0);                  // 배송비
    const [EtcPrice, setEtcPrice] = useState(0);                            // 기타비
    const [CommissionPer, setCommissionPer] = useState(0);                  // 마켓 수수료율

    /* 입력받는 값 */
    const [Commission, setCommission] = useState(0);                        // 수수료
    const [SettlementPrice, setSettlementPrice] = useState(0);              // 정산가
    const [SellerMarginPer, setSellerMarginPer] = useState(0);              // 마진율
    const [SellerMargin, setSellerMargin] = useState(0);                    // 마진



    useEffect(() => {
        if (PurchasePrice > 0 && SalePrice > 0) {
            setCommission(SalePrice * (CommissionPer / 100));
            setSettlementPrice(SalePrice - ShippingPrice - Commission);
            setSellerMargin(SalePrice - ShippingPrice - Commission - EtcPrice - PurchasePrice);
            setSellerMarginPer(SellerMargin / SettlementPrice * 100);
            // console.log(PurchasePrice + ' / ' + SalePrice + ' / ' + ShippingPrice + ' / ' + EtcPrice + ' / ' + CommissionPer);
        } else {
            setCommission(0);
            setSettlementPrice(0);
            setSellerMargin(0);
            setSellerMarginPer(0);
        }
    }, [Commission, CommissionPer, EtcPrice, PurchasePrice, SalePrice, SellerMargin, SellerMarginPer, SettlementPrice, ShippingPrice]);

    return (
        <div className="Contents-Container">
            <div className="Contents Contents-Long">
                <div className="Calculator-Contents">
                    <div className="Calculator-Tabs">
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(0)}>판매가계산기</div>
                        <div className="Calculator-Tab Calculator-Tab-Deco">판매가 마진계산기</div>
                        <div className="Calculator-Tab" onClick={()=>Props.setPage(2)}>부가세계산기</div>
                    </div>

                    <div className="Calculator-Values">
                        <div className="Calculator-Text">매입가 (원)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="10000" value={PurchasePrice} onChange={el => {
                                let num : any = el.target.value;
                                setPurchasePrice(num);
                            }} />
                            <input type="number" value={PurchasePrice} onChange={el => {
                                let num : any = el.target.value;
                                setPurchasePrice(num);
                            }} />
                            <span>원</span>
                        </div>
                        <div className="Calculator-Text">판매가 (원)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="10000" value={SalePrice} onChange={el => {
                                let num : any = el.target.value;
                                setSalePrice(num);
                            }} />
                            <input type="number" value={SalePrice} onChange={el => {
                                let num : any = el.target.value;
                                setSalePrice(num);
                            }} />
                            <span>원</span>
                        </div>
                        <div className="Calculator-Text">배송비 (원)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="10000" value={ShippingPrice} onChange={el => {
                                let num : any = el.target.value;
                                setShippingPrice(num);
                            }} />
                            <input type="number" value={ShippingPrice} onChange={el => {
                                let num : any = el.target.value;
                                setShippingPrice(num);
                            }} />
                            <span>원</span>
                        </div>
                        <div className="Calculator-Text">기타비용 (원)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="10000" value={EtcPrice} onChange={el => {
                                let num : any = el.target.value;
                                setEtcPrice(num);
                            }} />
                            <input type="number" value={EtcPrice} onChange={el => {
                                let num : any = el.target.value;
                                setEtcPrice(num);
                            }} />
                            <span>원</span>
                        </div>
                        <div className="Calculator-Text">마켓 수수율 (%)</div>
                        <div className="Calculator-Inputs">
                            <input type="range" max="100" value={CommissionPer} onChange={el => {
                                let num : any = el.target.value;
                                setCommissionPer(num);
                            }} />
                            <input type="number" value={CommissionPer} onChange={el => {
                                let num : any = el.target.value;
                                setCommissionPer(num);
                            }} />
                            <span>%</span>
                        </div>
                    </div>

                </div>
                <div className="Result-Contents">
                    <div className="Result-Title">판매가 마진 계산기</div>
                    <div className="Result-Value"><span>{SellerMargin.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 마진</div>
                    <div className="Result-Value"><span>{SellerMarginPer.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} %</span>&nbsp; / 마진율</div>
                    <div className="Result-Value"><span>{SettlementPrice.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 정산금액</div>
                    <div className="Result-Value"><span>{Commission.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} 원</span>&nbsp; / 마켓 수수료</div>
                    <img className="Result-Image" src={dollorImage}/>
                </div>
            </div>
        </div>
    );
}

export default SaleMargin;
